import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => (
  // <div className={styles.root}>
  //   <div className={styles.wrapper}>
  //     <div className={styles.branding}>
  //       <Link to='/'>{siteTitle}</Link>
  //     </div>

  //     
  //   </div>
  // </div>
  <div className="landing-container lighter">
  <div className="navbar p-5 pt-3 pb-3" id="topbar">
    <div className="flex-center">
      <a className="nav-logo text-black" href="/">
        <img src="https://divuture.com/icon.png" alt="logo" className="logo pr-2"/>
      </a>
      <Link className="nav-logo text-black" to='/'>Divuture Blog</Link>
    </div>


       <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
       </button>
       {showNav ?
       
       <div className="mobile-nav">
         <ul>
           <li>
             <a href='https://www.divuture.com'>Back to Main Website</a>
           </li>
           <li>
             <a href='https://www.divuture.com/login'>Login</a>
           </li>
           <li>
             <a href='https://www.divuture.com/register'>Register</a>
           </li>
         </ul>
       </div>
       :
       <></>}
    <div className="flex hideOnMobile">
      <a href="https://www.divuture.com" className="nav-link text-black font-weight-bold mr-2 p-3">Back to Main Website</a>
    </div>
    <div className="flex hideOnMobile">
      <a className="nav-link text-black font-weight-bold mr-2 p-3" href="https://www.divuture.com/login">Login</a>
      <a className="nav-link text-black font-weight-bold mr-2 p-3" href="https://www.divuture.com/register">Register</a>
    </div>
  </div>
  </div>

)

export default Header
